
export enum TrackingEvent {
  NewExpertProfile = "New Expert Profile",
  InviteRequest = "Invite Request",
  InviteSent = "Invite Sent",
  FileUpload = "File Upload",
  Feedback = "Feedback",
  CopyText = "CopyText",
  CopyLink = "CopyLink",
  ShareUrl = "ShareUrl",
  RelatedClick = "RelatedClick",

  // deprecated
  ConversationStarted = "Conversation Started",
  ReferralMade = "Referral Made",

  // magic moment tracking
  // Note: these aren't first day only
  FirstMessageInFirstDay = "First Message In First Day",
  SecondMessageInFirstDay = "Second Message In First Day",
  FifthMessageInFirstDay = "Fifth Message In First Day",
  TenthMessageInFirstDay = "Tenth Message in First Day",
  FortySecondMessageInFirstDay = "Forty Second Message in First Day",

  // files tracking
  FileUploaded = "FileUploaded",
  FileDrafted = "FileDrafted",

  // sign up for pro (paid) plan
  Pro79Signup = "ProSignup",
  Pro10Signup = "Pro10Signup",

  // seo pages tracking
  SEOPageVisit = "SEOPageVisit",
  SEOPageAction = "SEOPageAction",

  // page load timing data
  PageLoadTiming = "PageLoadTiming",

  // home page tracking
  HomePageAction = "HomePageAction",

}
